import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import useMediaRecorder, { Status } from './useMediaRecorder';
import {
  StopButton,
  StyledMicIcon,
  StyledMicOffIcon,
  StyledStopIcon,
  TimeLabel,
} from './Recorder.styled';
import MicIcon from '../Icons/MicIcon';
import MicDisabledIcon from '../Icons/MicDisabledIcon';
import { palette  } from '../../theme/palette';

interface Props {
  onRecord: (blob: File) => void;
  delayTime?: number;
  disable: boolean;
  setIsRecording?: Function,
  showElapsedTime?: boolean,
  recordButtonHeight?: number,
  recordButtonWidth?: number,
  maxRecordingTime?: number,
}


const Recorder = ({
  onRecord,
  delayTime,
  disable,
  setIsRecording,
  showElapsedTime = true,
  recordButtonHeight = 72,
  recordButtonWidth = 72,
  maxRecordingTime,
}: Props) => {
  const {
    elapsedTime,
    startRecording,
    stopRecording,
    status,
  } = useMediaRecorder({
    onError: (err) => console.log(err),
    onStop: (blob) => {
      if (blob) {
        const currentTime = new Date();
        // @ts-ignore
        // eslint-disable-next-line no-param-reassign
        blob.lastModifiedDate = currentTime;
        // @ts-ignore
        // eslint-disable-next-line no-param-reassign
        blob.name = currentTime.getTime().toString();
        onRecord(blob as File);
      }
    },
    mediaStreamConstraints: { audio: true, video: false },
  });

  const onStart = () => {
    startRecording(0);
    setIsRecording?.(true);
  };

  const onStop = () => {
    stopRecording();
    setIsRecording?.(false);
  };


  const isRecording = [Status.Recording, Status.Stopping, Status.Paused].includes(status);
  const canRecord = [Status.Idle, Status.AcquiringMedia, Status.Ready, Status.Failed, Status.Stopped].includes(status);

  useEffect(() => {
    if (status === Status.Recording && maxRecordingTime && elapsedTime >= maxRecordingTime) {
      onStop();
    }
  }, [maxRecordingTime, status, elapsedTime, onStop]);

  const secsToTimeFormat = () => {
    var mins = Math.floor(elapsedTime / 60);
    var seconds = (elapsedTime - mins * 60);
    return `${mins < 10 ? '0' : ''}${mins}:${seconds < 10 ? '0' : ''}${seconds.toFixed(1)}`;
  };

  return (
    <Box>
      {
        showElapsedTime && (
          <Box
            marginBottom={1}
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
          >
            <TimeLabel>
              {`${secsToTimeFormat()}`}
            </TimeLabel>
          </Box>
        )
      }
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
      >
        {(delayTime && isRecording && delayTime > elapsedTime) || disable ? (
          <Box
            marginLeft={4}
            marginRight={4}
          >
            <StyledMicOffIcon variant="contained" disabled sx={{ width: recordButtonWidth, height: recordButtonHeight }}>
              <MicDisabledIcon pathFill={palette.customBlack.lightBlack} />
            </StyledMicOffIcon>
          </Box>
        ) : (
          <>
            {
              canRecord && (
                <Box
                  marginLeft={4}
                  marginRight={4}
                  onClick={onStart}
                >
                  <StyledMicIcon variant="contained" color="primary" sx={{ width: recordButtonWidth, height: recordButtonHeight }}>
                    <MicIcon pathFill={palette.customWhite.main} />
                  </StyledMicIcon>
                </Box>
              )
            }
            {
              isRecording && (
                <Box
                  marginLeft={4}
                  marginRight={4}
                  onClick={onStop}
                >
                  <StopButton size="large" color="primary" sx={{ width: recordButtonWidth, height: recordButtonHeight }}>
                    <StyledStopIcon />
                  </StopButton>
                </Box>
              )
            }
          </>
        )}
      </Box>
    </Box>
  );
};
export default React.memo(Recorder);
