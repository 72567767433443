enum RoutingPaths {
  LogIn = '/login',
  LogInPasswordEnable = '/login/:districtId/:username/:password',
  LogInDistrictId = '/login/:districtId',
  ForgotPassword = '/forgot-password',
  Root = '/',
  Grade = '/grade',
  Students = '/students',
  Assignments = '/assignments',
  ResetPassword = '/reset-password',
  Districts = '/districts',
  Teachers = '/teachers',
  Schools = '/schools',
  AdminClasses = '/admin-classes',
  AdminClass = '/admin-classes/:id',
  AdminClassManage = '/admin-classes/:id/manage',
  Classes = '/classes',
  Class = '/classes/:id',
  CreateClass = '/classes/create',
  EditClass = '/classes/:id/edit',
  AdminStudents = '/admin-students',
  Settings = '/settings',
  Admins = '/admins',
  CreateAdmin = '/admin/create',
  AdminAnalytics = '/admin/analytics',
  SectionAnalytics = '/analytics/:section',
  Analytics = '/analytics',
  SubmitTask = '/tasks/:id/submit',
  ScoreTask = '/tasks/:id/score/:section',
  ToolBox = '/toolbox',
  BackPack = '/backpack',
  Scores = '/progress/score',
  StudentProgress = '/progress',
  Groups = '/groups',
  DistrictSync = '/syncDistricts',
  Performance = '/admin/analytics',
  LearningGoals = '/admin/analytics/learning-goals',
  DistrictWideComparison = '/admin/analytics/district-wide-comparisons',
  TabKeyDistrictWideComparison = '/admin/analytics/district-wide-comparisons/:tabKey',
  AdminsTable = '/admins/:type',
  CreateAssignment = '/assignments/create',
  EditAssignment = '/assignments/:id/edit',
  AssignmentsTable = '/assignments/:section',
  CreateDistrict = '/admin/create/district',
  EditDistrict = '/admin/create/district/:id',
  CreateSchool = '/admin/create/school',
  EditSchool = '/admin/create/school/:id',
  CreateStudent = '/admin/create/student',
  EditStudent = '/admin/create/student/:id',
  AdminFormCreate = '/admin/create/admin',
  CreateDemo = '/admin/create/demo',
  CreateTeacher = '/admin/create/teacher',
  EditTeacher = '/admin/create/teacher/:id',
  AdminCreateClass = '/admin/create/class',
  AdminEditClass = '/admin/create/class/:id',
  UploadImages = '/settings/upload-images',
  ManageImages = '/settings/manage-images/:id',
  StudentBackPackView = '/backpack/:id/view',
  Student = '/students/:id/view/:section',
  SpeakingTask = '/tasks/:id/submit/speaking',
  WritingTask = '/tasks/:id/submit/writing',
  AdminBenchmarks = '/benchmarks',
  TeacherBenchMarks = '/teacher/benchmarks',
  ScoreTaskBenchmarks = '/tasks/:id/benchmark/score',
  AnalyticsOverview = '/analytics/overview',
  TeacherGradeBenchMarks = '/teacher/benchmarks/grade',
  GradeBenchMarks = '/benchmarks/grade',
  TeacherBenchMarksDashboard = '/teacher/benchmarks/dashboard',
  StudentBenchmarkDashboard = '/teacher/student-dashboard/:id',
  TeacherClasses = '/teacher/classes',
  AdminBenchmarkReport = '/admin/benchmark-report',
}

export default RoutingPaths;
