import React from "react";

import jsPDF from "jspdf";
import { BenchmarkProgressScoreNode, BenchmarkProgressScoreSubmissionType } from "../../../generated/graphql";
import autoTable, { RowInput } from "jspdf-autotable";
import { drawAutotableRowBorders, drawColumnBorders, drawDownArrow, drawHyphen, drawSmallAutotableCellChipsWithText, drawUpArrow } from "../../../utils/pdf";




interface Props {
    pdfDoc: jsPDF,
    posY: number,
    styles: any,
    progressScoresData: BenchmarkProgressScoreNode[],
}

export const ProgressScoresPdfTable = ({ pdfDoc, posY, styles, progressScoresData }: Props) => {
    let positionY = 0;
    const drawAutotableCellProgressScoreIcons = (data: any, pdfDoc: jsPDF, columns: number[]) => {
        const { row, column, cell } = data;
        const textX = cell.x + (data.cell.width - 13) / 2;
        const textY = cell.y + data.cell.height / 3;
        // Skip the header row
        if (row.section === 'head') return;
        // Check if the current cell is in the specified column
        if (columns.includes(column.index)) {
            let svgString = '';
            if (cell.raw === '') {
                drawHyphen(pdfDoc, textX + 2, textY + 3, textX + 8, textY + 3, 1)
            } else if (cell.raw === 'up') {
                drawUpArrow(pdfDoc, textX + 2, textY + 2, 4);
            } else if (cell.raw === 'down') {
                drawDownArrow(pdfDoc, textX + 2, textY + 2, 4);
            }

        }

    };
    const drawAutotableCellEmptyIcons = (data: any, pdfDoc: jsPDF, columns: number[]) => {
        const { row, column, cell } = data;
        const textX = cell.x + (data.cell.width - 13) / 2;
        const textY = cell.y + data.cell.height / 3;
        // Skip the header row
        if (row.section === 'head') return;
        // Check if the current cell is in the specified column
        if (columns.includes(column.index)) {
            let svgString = '';
            if (cell.raw === '') {
                drawHyphen(pdfDoc, textX + 2, textY + 3, textX + 8, textY + 3, 1)
            }

        }

    };

    const progressScoresTable = {
        0: {
            'key': 'student_name',
            'name': 'Name',
        },
        1: {
            'key': 'grade',
            'name': 'Grade',
        },
        2: {
            'key': 'speaking_boy_score',
            'name': 'BOY',
        },
        3: {
            'key': 'speaking_boy_to_moy_progress',
            'name': '',
        },
        4: {

            'key': 'speaking_moy_score',
            'name': 'MOY',
        },
        5: {
            'key': 'speaking_moy_to_eoy_progress',
            'name': '',
        },
        6: {
            'key': 'speaking_eoy_score',
            'name': 'EOY',
        },
        7: {
            'key': 'speaking_ytd_change',
            'name': 'YTD Change',
        },
        8: {
            'key': 'writing_boy_score',
            'name': 'BOY'
        },
        9: {
            'key': 'writing_boy_to_moy_progress',
            'name': '',
        },
        10: {

            'key': 'writing_moy_score',
            'name': 'MOY',
        },
        11: {
            'key': 'writing_moy_to_eoy_progress',
            'name': '',
        },
        12: {
            'key': 'writing_eoy_score',
            'name': 'EOY',
        },
        13: {
            'key': 'writing_ytd_change',
            'name': 'YTD Change',
        },

    }


    const getColVal = (score: any, colkey: string) => {
        const speakingScore = score?.score_by_submission_type?.find?.((score_by_type: BenchmarkProgressScoreSubmissionType) => score_by_type?.submission_type === 'Speaking');
        const writingScore = score?.score_by_submission_type?.find?.((score_by_type: BenchmarkProgressScoreSubmissionType) => score_by_type?.submission_type === 'Writing');
        if (colkey === 'student_name') {
            return `${score?.student_first_name}  ${score?.student_last_name}`;
        }
        if (colkey === 'speaking_boy_score') {
            return speakingScore?.boy_score;
        }
        if (colkey === 'speaking_moy_score') {
            return speakingScore?.moy_score;
        }
        if (colkey === 'speaking_eoy_score') {
            return speakingScore?.eoy_score;
        }
        if (colkey === 'writing_boy_score') {
            return writingScore?.boy_score;
        }
        if (colkey === 'writing_moy_score') {
            return writingScore?.moy_score;
        }
        if (colkey === 'writing_eoy_score') {
            return writingScore?.eoy_score;
        }
        if (colkey === 'speaking_ytd_change') {
            return speakingScore?.ytd_change;
        }
        if (colkey === 'writing_ytd_change') {
            return writingScore?.ytd_change;
        }
        if (colkey === 'speaking_moy_to_eoy_progress') {
            return speakingScore?.moy_to_eoy_progress;
        }
        if (colkey === 'speaking_boy_to_moy_progress') {
            return speakingScore?.boy_to_moy_progress
        }
        if (colkey === 'writing_boy_to_moy_progress') {
            return writingScore?.boy_to_moy_progress
        }
        if (colkey === 'writing_moy_to_eoy_progress') {
            return writingScore?.moy_to_eoy_progress
        }
        return score[colkey];
    };

    const tableHeader1: RowInput = [];
    const tableHeader2: RowInput = [];
    tableHeader1.push({ colSpan: 2, content: '' }, { colSpan: 6, content: 'Speaking' }, { colSpan: 6, content: 'Writing' });
    Object.values(progressScoresTable).forEach((column: any) => tableHeader2.push(column.name));

    const tableRows: RowInput[] = [];
    progressScoresData.forEach(score => {
        const dataRow: RowInput = [];
        Object.values(progressScoresTable).forEach(column => dataRow.push(getColVal(score, column.key)));
        tableRows.push(dataRow);
    });

    autoTable(pdfDoc, {
        head: [tableHeader1, tableHeader2],
        body: tableRows,
        theme: 'plain',
        margin: styles.margin,
        headStyles: styles.header,
        styles: styles.rows,
        columnStyles: styles.columns,
        didDrawCell: (data) => {
            drawAutotableRowBorders(data, pdfDoc);
            drawSmallAutotableCellChipsWithText(data, pdfDoc, [2, 4, 6, 8, 10, 12], 3);
            drawAutotableCellProgressScoreIcons(data, pdfDoc, [3, 5, 9, 11]);
            drawColumnBorders(data, pdfDoc, [7]);
            drawAutotableCellEmptyIcons(data, pdfDoc, [7, 13]);

        },

        didDrawPage: (data) => {
            positionY = (data.cursor?.y ?? 0);
        },

        startY: posY,
    });
    return positionY;

};