/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Box } from '@mui/material';
import { AudioFileType } from '../../../variables/types';
import { SPEAKING_AUDIO_ALERT_MESSAGE } from '../../../variables/constant';
import CollapsedAlert from '../../../components/CollapsedAlert';
import AudioPlayerWithVolumeControl from '../../../components/PlayerWithVolumeControl';
import { palette  } from '../../../theme/palette';

interface Props extends AudioFileType {
  setIsPlaying?: Function
  disablePlay?: boolean
  open: boolean
  setOpen: Function
  recordingCount: number
  currentRecordingIndex: number
  handleCurrentRecordingChange: Function
}

const SpeakingPlayer = (props: Props) => {
  const { setIsPlaying, open, setOpen, currentRecordingIndex, handleCurrentRecordingChange, recordingCount } = props;
  return (
    <Box display="flex" flexDirection="column" alignItems="initial" width="100%">
      <Box paddingBottom="1%">
        <CollapsedAlert
          message={SPEAKING_AUDIO_ALERT_MESSAGE}
          open={open}
          setOpen={setOpen}
        />
      </Box>
      <Box
        border={`2px solid ${palette.customBackground.disabled}`}
        borderRadius="12px"
        padding={1}
      >
        <AudioPlayerWithVolumeControl
          showLabel
          {...props}
          setIsPlaying={setIsPlaying}
          flexStructure={false}
          currentRecordingFileIndex={currentRecordingIndex}
          handleCurrentRecordingChange={handleCurrentRecordingChange}
          recordingCount={recordingCount}
        />
      </Box>
    </Box>
  );
};

export default React.memo(SpeakingPlayer);
