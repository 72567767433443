import React, { useContext, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';
import Recorder from '../../../components/Recorder';
import {
  STUDENT_AUDIO_FILE_LIMIT,
  STUDENT_RECORDING_DELAY_TIME,
} from '../../../variables/constant';
import SpeakingPlayer from './SpeakingPlayer';
import NextButton from '../Common/NextButton';
import {
  File as APIFIle,
  SubmissionStatus,
} from '../../../generated/graphql';
import Loader from '../../../components/Loader/loader';
import useSpeakingTask from './speaking-task-hook';
import SaveAndQuitButon from '../Common/SaveAndQuitButon';
import BackButton from '../Common/BackButton';
import { SubmitTaskContext } from '../submit-task-context';
import SpeakingModal from './SpeakingModal';

interface Props {
  id: string,
  speakings: Array<{ __typename?: 'File' } & Pick<APIFIle, 'url' | 'file_type'>>;
  refreshSubmissionData: Function;
}

const styles = {
  parentFlex: {
    display: 'flex',
    alignItems: 'end',
    justifyContent: 'space-between',
    zIndex: 1,
    marginTop: '-55px',
    minHeight: '194px',
  },
};

const SpeakingTask = ({ id, speakings, refreshSubmissionData }: Props) => {
  const {
    records,
    audioData,
    loading,
    onRecord,
    onStatusChange,
    isRecording,
    setIsRecording,
    isPlaying,
    setIsPlaying,
    open,
    setOpen,
    handleCurrentRecordingChange,
    currentRecordingFileIndex,
  } = useSpeakingTask({ id, speakings, refreshSubmissionData });

  const {
    setShowHelp,
    submission,
    setShowGoals,
    isSpeakingWritingPromptPlaying,
  } = useContext(SubmitTaskContext);
  const [openSpeakingModal, setOpenSpeakingModal] = useState<boolean>(false)
  const handleSpeakingConfirmation = () => {
    setOpenSpeakingModal(true);
  }

  const handleNext = async () => {
    await onStatusChange();
    if (!(submission && submission?.getGoalsForSubmission?.writing?.length)) {
      setShowGoals?.(false);
      setShowHelp?.(true);
    } else {
      setShowHelp?.(false);
      setShowGoals?.(true);
    }
  };

  const history = useHistory();
  const hasReachedRecordingLimit = records?.length >= STUDENT_AUDIO_FILE_LIMIT;
  return (
    <>
      <Loader open={loading} />
      <Box
        sx={{ ...styles.parentFlex }}
      >
        {/* Back and Save and Quit button */}
        <Box
          display="flex"
          mt={6}
          flex="2"
        >
          <Box>
            {
              (submission?.getBenchmark?.back_navigation_allowed || submission?.assignment?.back_navigation_allowed) && (
                <BackButton disabled={isRecording} handleBack={() => history.push(`/tasks/${id}/submit/annotation`)} />
              )
            }

          </Box>
          <Box ml={1}>
            <SaveAndQuitButon disabled={isRecording} />
          </Box>
        </Box>

        {/* Recorder */}
        <Box>
          <Box>
            <Recorder
              disable={hasReachedRecordingLimit || isPlaying}
              onRecord={onRecord}
              delayTime={STUDENT_RECORDING_DELAY_TIME}
              setIsRecording={setIsRecording}
            />
          </Box>
          <Box mt={1} ml={4.5}>
            {!hasReachedRecordingLimit && (
              <Typography>
                {`You are allowed to record ${STUDENT_AUDIO_FILE_LIMIT - records.length ?? 0} more time(s)`}
              </Typography>
            )}
          </Box>
        </Box>

        {/* Next button */}
        <Box
          flex="2"
          display="flex"
          alignItems="end"
          justifyContent="space-between"
        >
          <Box ml={2}>
            {audioData && (
              <SpeakingPlayer
                fileType={audioData.file_type}
                url={audioData.url}
                setIsPlaying={setIsPlaying}
                disablePlay={isRecording}
                open={open}
                setOpen={setOpen}
                recordingCount={records.length}
                currentRecordingIndex={currentRecordingFileIndex}
                handleCurrentRecordingChange={handleCurrentRecordingChange}
              />
            )}
          </Box>
          <Box mt={6} ml={1}>
            <NextButton
              status={SubmissionStatus.AnnotationCompleted}
              handleNext={handleSpeakingConfirmation}
              disabled={isRecording || isSpeakingWritingPromptPlaying}
              showConfirmationDialog={!records?.length}
            />
          </Box>
        </Box>
        <SpeakingModal openModal={openSpeakingModal} handleClose={() => { setOpenSpeakingModal(false) }} handleNext={handleNext} speakings={speakings} />
      </Box>
    </>
  );
};

export default React.memo(SpeakingTask);
