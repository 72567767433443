import React from 'react';
import { Box, Typography } from '@mui/material';
import useScore from '../score-hook';
import FeedbackRow from '../Common/StudentFeedback/FeedbackRow';
import BodyContainer from '../BodyContainer';
import ScoringCard from '../Common/ScoringCardWithTooltip';
import ClickAndLabelTask from '../../SubmitTask/ClickAndLabel/ClickAndLabelTask';
import RecordingCard from './RecordingCard';
import ScoreTaskLayout from '../ScoreTaskLayout';
import { ScoreTabs } from '../../../variables/types';
import TeacherNotes from '../Common/TeacherNotes';
import GoalSection from '../../../components/GoalSection';
import useAdditionalFeedback from '../Common/StudentFeedback/additional-feedback-hook';
import { StyledContainer2 } from '../ScoreTask.styled';
import BackdropModal from '../../../components/BackdropModal/BackdropModal';
import PTPromptCard from '../Common/PTPromptCard';
import { NewSubmission } from '../../../generated/graphql';

const Speaking = () => {
  const {
    id,
    submission,
    setNotes,
    saveNotes,
    showPreview,
    togglePreview,
    notes: teacherNotes,
    loading: scoreLoading,
  } = useScore();
  const {
    notes: feedbackNotes,
    onSave,
    loading: additionalFeedbackLoading,
    onDelete,
    onRecord,
    audioData,
    isPlaying,
    updateNotes: saveAdditionalFeedback,
    handleStartRecording,
    setIsPlaying,
  } = useAdditionalFeedback({
    id: id ?? '',
    textFeedback: submission?.getAdditionalFeedback?.feedback_notes ?? '',
    audioFeedback: submission?.getAdditionalFeedback?.audio?.[0] || null,
  });

  const loading = additionalFeedbackLoading || scoreLoading;

  const bodyContent = (
    <>
      <Box>
        <Box>
          <StyledContainer2>
            <Box
              display="flex"
              flexDirection="column"
              sx={{
                gridRowGap: 10,
              }}
            >
              <Box ml="20px" mt="20px">
                <Typography variant='sContentHeader'>{submission?.student?.first_name ? `${submission?.student?.first_name}'s Submission` : ''}</Typography>
              </Box>
              <Box>
                <ClickAndLabelTask
                  togglePreview={togglePreview}
                  submission={submission as NewSubmission}
                  annotations={submission?.annotations || []}
                  disableEdit
                  containerHeight="40vh"
                />
              </Box>
            </Box>
          </StyledContainer2>
        </Box>
        <Box mt="30px">
          <PTPromptCard taskType={ScoreTabs.Speaking} />
        </Box>
        <Box mt="30px">
          <RecordingCard />
        </Box>
        <Box mt="30px">
          <TeacherNotes
            submissionStatus={submission?.status ?? ''}
            setFeedback={setNotes}
            feedback={teacherNotes}
          />
        </Box>
      </Box>
      <Box>
        <Box>
          <ScoringCard taskType={ScoreTabs.Speaking} />
        </Box>
        <Box mt="30px">
          <GoalSection goalTitle={ScoreTabs.Speaking} />
        </Box>
        <Box mt="30px">
          <FeedbackRow
            loading={loading}
            onRecord={onRecord}
            audioData={audioData}
            isPlaying={isPlaying}
            notes={feedbackNotes}
            updateNotes={saveAdditionalFeedback}
            onDelete={onDelete}
            setIsPlaying={setIsPlaying}
            handleStartRecording={handleStartRecording}
            submissionStatus={submission?.status ?? ''}
          />
        </Box>
      </Box>
      <BackdropModal open={showPreview} onClose={togglePreview}>
        <ClickAndLabelTask
          submission={submission as NewSubmission}
          annotations={submission?.annotations || []}
          disableEdit
          containerHeight="80vh"
        />
      </BackdropModal>
    </>
  );
  return (
    <ScoreTaskLayout
      saveTeacherNotes={saveNotes}
      saveTeacherFeedback={onSave}
      body={<BodyContainer bodyContent={bodyContent} />}
    />
  );
};
export default Speaking;
