import React from 'react';
import Layout from '../../../components/Layout';
import { Box, SxProps, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import MultiSelectTeacherGradeFilter from '../../../components/MultiSelectTeacherGradeFilter';
import { AdminBenchmarkReportStylesCSS } from './adminBenchmarkReport.styled';
import BenchmarkSummary from './BenchmarkSummary';
import useAdminBenchmarkReport from './admin-benchmark-report-hook';
import { TeacherBenchmarkDashboardContextInit } from '../../TeacherBenchmarksDashboard/teacherbenchmarks-dashboard-hook';
import { AdminBenchmarkSummaryWidgetDataCheck } from '../../../variables/constant';
import { TeacherBenchmarksDashboardContext } from '../../TeacherBenchmarksDashboard/teacherbenchmarks-dashboard-context';
import MultiSelectTeacherSchoolFilter from '../../../components/MultiSelectTeacherSchoolFilter';
import { toReadableFormat } from '../../../utils/dateFormat';
import { SchoolValue } from '../../../components/SchoolFilterTeacher';
import MultiSelectAdminTeacherFilter from '../../../components/MultiSelectAdminTeacherFilter';
import { GradeDto } from '../../../generated/graphql';
import ExportToPdf from './BenchmarkSummary/pdfExport';

const AdminBenchmarkReport = () => {
  const theme = useTheme();
  const {
    loading,
    setLoading,
    areWidgetsLoading,
    setAreWidgetsLoading,
    dashboardWidgetsDataStatus,
    setDashboardWidgetsDataStatus,
    isDataMissingInAllWidgets,
    setIsDataMissingInAllWidgets,
    localTeacherBenchmarkDashboardFilters,
    getLocalTeacherBenchmarkDashboardFilters,
    setLocalTeacherBenchmarkDashboardFilters,
    selectedClasses, 
    setSelectedClasses,
    selectedSchools,
    setSelectedSchools,
    selectedGrades,
    setSelectedGrades,
    onSchoolChange,
    onGradeChange, 
    onTeacherChange,
    benchmarkType,
    setBenchmarkType,
    selectedTeachers,
    setSelectedTeachers,
    isAdminReportBSGrowthDataMissing,
    setIsAdminReportBSGrowthDataMissing,
    isAdminReportBSIndicatorDataMissing,
    setIsAdminReportBSIndicatorDataMissing,
  } = TeacherBenchmarkDashboardContextInit({ 
    dashboardWidgets:
    AdminBenchmarkSummaryWidgetDataCheck.data,
  });

  const {
    schoolsData,
    lastSnowflakeUpdateTimeData,
    getLastSnowflakeUpdateTimeLoading,
    gradesData,
    teachers,
    isDistrictAdmin,
    isSchoolAdmin,
    districtId,
    schoolId,
    loadingSchools,
    loadingTeachers,
    gradesLoading,
  } = useAdminBenchmarkReport({
    selectedGrades,
    selectedTeachers,
    selectedSchools,
  });

  const styles = AdminBenchmarkReportStylesCSS(theme);
  return (
    <TeacherBenchmarksDashboardContext.Provider value={{
      selectedGrades,
      selectedClasses,
      selectedSchools,
      selectedTeachers,
      setSelectedClasses,
      setSelectedGrades,
      setSelectedSchools,
      setSelectedTeachers,
      loading, setLoading,
      areWidgetsLoading, setAreWidgetsLoading,
      dashboardWidgetsDataStatus, setDashboardWidgetsDataStatus,
      isDataMissingInAllWidgets, setIsDataMissingInAllWidgets,
      benchmarkType, setBenchmarkType,
      lastSnowflakeUpdateTimeData, 
      getLastSnowflakeUpdateTimeLoading,
      localTeacherBenchmarkDashboardFilters,
      getLocalTeacherBenchmarkDashboardFilters,
      setLocalTeacherBenchmarkDashboardFilters,
      isAdminReportBSGrowthDataMissing,
      setIsAdminReportBSGrowthDataMissing,
      isAdminReportBSIndicatorDataMissing,
      setIsAdminReportBSIndicatorDataMissing,
    }} >  
      <Layout padding={0}>
        <Box display="flex"
          flexDirection="column"
          width="100%"
          sx={{ ...styles.dashBoardContainer, padding: 0, zIndex: 1 }}
          id="admin-benchmark-report">
          <Box sx={styles.dashboard as SxProps} pl={1}  >
            <Box
              display="flex"
              justifyContent="space-between"
              paddingLeft={4}
              paddingRight={4}
            >
              <Box>
                <Typography variant="pageTitle"  >
                Benchmark Summary
                </Typography>
                {!!lastSnowflakeUpdateTimeData && !getLastSnowflakeUpdateTimeLoading && <Box marginTop={-0.5} >
                  <Typography variant="toolTipGreyText">
                    Last Updated: {toReadableFormat(lastSnowflakeUpdateTimeData, {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                    second: 'numeric',
                    timeZone: 'UTC',
                  }, 'en-US').replace('at', '')} UTC
                  </Typography>
                </Box>}
              </Box>
              <Box
                display="grid"
                gridTemplateColumns="repeat(3, 270px)"
                sx={{
                  gridGap: 4,
                  height: '48px',
                  maxHeight: '48px',
                }}
              >
                <Box>
                  {isDistrictAdmin && (<MultiSelectTeacherSchoolFilter
                    schools={schoolsData as SchoolValue[]}
                    value={selectedSchools}
                    onChange={onSchoolChange}
                    placeholder="All Schools"
                    isStartAdornment={false}
                    limitTags={1}
                    zIndexPopper={1000}
                    disabled={loadingSchools}
                  />)}
                </Box>
                <Box>
                  <MultiSelectTeacherGradeFilter
                    grades={gradesData as GradeDto[]}
                    value={selectedGrades}
                    onChange={onGradeChange}
                    placeholder="All Grades"
                    isStartAdornment={false}
                    limitTags={1}
                    zIndexPopper={1000}
                    disabled={gradesLoading}
                  />
                </Box>
                <Box>
                  <MultiSelectAdminTeacherFilter
                    teachers={teachers}
                    value={selectedTeachers}
                    onChange={onTeacherChange}
                    placeholder="All Teachers"
                    isStartAdornment={false}
                    limitTags={1}
                    zIndexPopper={1000}
                    disabled={loadingTeachers}
                  />
                </Box>
              </Box>
            </Box>
            <Box
              flexDirection="row"
              width="100%"
              pt={theme.spacing(3)}
              pl={4}
              pr={4}
            >
              <ExportToPdf lastSnowflakeUpdateTimeData={lastSnowflakeUpdateTimeData} />
              <BenchmarkSummary districtId={districtId as string} schoolId={schoolId as string} />
            </Box>
          </Box>
        </Box>
      </Layout>
    </TeacherBenchmarksDashboardContext.Provider>);
};

export default AdminBenchmarkReport;