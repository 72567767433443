import React from 'react';
import ScoringHeader from './ScoringHeader';
import { SubmissionStatus } from '../../../generated/graphql';
import { StyledFlexColBoxContainer } from '../../../components/Common/Common.styled';
import { StyledTeacherNotesInput } from '../ScoreTask.styled';

interface TeacherFeedbackProps {
  feedback?: string,
  setFeedback: Function,
  submissionStatus: string,
}

const TeacherNotes = ({
  feedback,
  setFeedback,
  submissionStatus,
}: TeacherFeedbackProps) => {

  return (
    <StyledFlexColBoxContainer>
      <ScoringHeader
        title="Teacher Notes"
        subTitle="Notes are private and will not be visible to the student."
        showInfo
      />
      <StyledTeacherNotesInput
        sx={{ padding: 0 }}
        placeholder="Type Here"
        defaultValue={feedback || ''}
        multiline
        rows={10}
        onInput={(event: React.ChangeEvent<HTMLInputElement>) => {
          setFeedback(event.target.value);
        }}
        disabled={submissionStatus === SubmissionStatus.Reviewed}
      />
    </StyledFlexColBoxContainer>
  );
};

export default TeacherNotes;
