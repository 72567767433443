import * as React from 'react';
import Button from '@mui/material/Button';
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip';
import AppToolTipIcon from '../Icons/AppToolTipIcon';
import { styled } from '@mui/material/styles';
import useAppToolTip from './appToolTip-hook';

interface Props {
  toolTipText?: string,
}
const AppToolTip = ({ toolTipText }: Props) => {
  const { open, handleOpen, handleClose } = useAppToolTip();
  const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.white,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      whiteSpace: 'pre-line', 
    },
    '&.MuiButtonBase-root.MuiButton-root':{
      '&:hover':{
        backgroundColor: theme.palette.common.white,
      },
    },
  }));

  return (
    <BootstrapTooltip
      open={open}
      onClose={handleClose}
      onOpen={handleOpen}
      title={toolTipText}
      placement='top-start'
    >
      <Button><AppToolTipIcon /></Button>
    </BootstrapTooltip>
  );
};

export default AppToolTip;
